import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const TeamProfiles = ({ gridItems }) => {
  const { profile, heading } = gridItems;
  const [on, toggle] = useState(false);
  const fadeIn = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.slow
  })
  return (
    <div className="columns is-multiline"
        style={{
          display: `flex`,
          alignItems: 'center'
    }}>
      <Waypoint
        bottomOffset="40%"
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      <div className="column is-half-mobile team-profile-section team-profile__intro">
          <h2 className="has-text-weight-semibold">
            {heading}
          </h2>
      </div>
      <div className="column is-12">
        <div className="columns team-profile-wrapper">
          {profile.map( (item, i) => (
            <div key={item.name} className="column is-6 is-half-mobile team-profile-section">
              <section >
                  <animated.div className="has-text-centered" style={fadeIn}>
                    <div className="team-profile__image" style={{
                        maxWidth: '120px',
                    }}>
                      <PreviewCompatibleImage imageInfo={item} />
                    </div>
                    <p className="item-name">{item.name}</p>
                    <p className="item-title">{item.title}</p>
                  </animated.div>
              </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

TeamProfiles.propTypes = {
  gridItems: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default TeamProfiles
