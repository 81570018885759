import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring, config } from 'react-spring'

const ClientLogos = ({ gridItems }) => {
  const [on, toggle] = useState(false);
  const fadeIn = useSpring({
    opacity: on ? 1 : 0,
    transform: on ? 'translate3d(0,0,0)' : 'translate3d(0, 50%, 0)',
    config: config.slow
  })
  return (
    <div className="columns is-multiline" 
        style={{
          display: `flex`,
          justifyContent: 'center'
    }}>
      <Waypoint 
        bottomOffset="30%"
        onEnter={() => { 
          if (!on) toggle(true);
        }}         
      />
      
      {gridItems.map( (item, i) => (
        <div key={i} className="column is-3"
          style={{
            justifyContent: 'center',
            alignSelf: 'center'        
          }}>
          <section >         
              <animated.div className="has-text-centered" style={fadeIn}>
                <div className="image__wrapper"
                  style={{
                    width: '180px',
                    display: 'inline-block',
                  }}
                >
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
              </animated.div>
          </section>
        </div>
      ))}
    </div>
  )
}

ClientLogos.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default ClientLogos
