import React from 'react'

const BorderLine = () => {    
  return (
    <div className="white-line-border">
      <hr/>
    </div>                            
  )
}

export default BorderLine
