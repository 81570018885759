import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import TeamProfiles from '../components/TeamProfiles'
import ClientLogos from '../components/ClientLogos'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import BorderLine from '../components/BorderLine'
import PreviewCompatibleBgImage from '../components/PreviewCompatibleBgImage'

export const AboutUsPageTemplate = ({
  title,
  metadescription,
  heroimagebg,
  mainpitch,
  whatwedo,
  meetushero,
  humanpurposebg,
  humanpurpose,
  whyhuman,
  community,
  teamprofiles,
  blogs,
  clientlogosbg,
  clientlogos,
  helmet,
}) => (
  <div className="aboutus-pg ">
    {helmet || ''}
      <div
        className="full-width-image margin-top-0 hero-bg"
        style={{
          backgroundImage: `url(${
            !!heroimagebg.childImageSharp ? heroimagebg.childImageSharp.fluid.src : heroimagebg
            })`,
          backgroundPosition: `bottom left`,
          justifyContent: 'left',
          flexWrap: `wrap`
        }}
      >
      <div className="container">
        <div className="hero-text"
          style={{
            display: 'flex',
            lineHeight: '1',
            alignItems: 'left',
            flexDirection: 'column',
          }}>

          <div className="columns">
            <div className="column is-12 main-text" style={{ alignSelf: 'center' }}>
              <div>
                <h1
                  className="has-text-weight-bold"
                  style={{
                    color: 'white',
                    lineHeight: '1.2',
                    padding: '0.25em',
                  }}
                >   {mainpitch.heading}
                </h1>
                <p className="subtitle">{mainpitch.description}</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">

                <div className="intro-section-wrapper aboutus-hero columns" >
                  <div className="column is-5" >
                    <h2>{whatwedo.heading}</h2>
                      {whatwedo.description.map((item, i) => (
                        <p>{item.text}</p>
                      ))}
                  </div>
                  <div className="column is-7" >
                    <PreviewCompatibleImage imageInfo={whatwedo.image} />
                  </div>
                </div>
                <BorderLine />
                <div className="difference-section-wrapper columns" >
                  <div className="column is-6" >
                    <div className="columns">
                      <TeamProfiles gridItems={teamprofiles} />
                    </div>
                  </div>
                  <div className="column is-6 difference-description" >
                      <div dangerouslySetInnerHTML={{ __html: teamprofiles.description }} />
                  </div>
                </div>
                <BorderLine />
                <div className="whyhuman-section-wrapper columns" >
                  <div className="column is-5 whyhuman-description" >
                    <div className="columns" style={{
                        flexWrap: `wrap`
                      }}>
                      <div className="column">
                        <h2>{whyhuman.heading}</h2>
                        <p>{whyhuman.description}</p>
                      </div>

                    </div>
                  </div>
                  <div className="column is-7 difference-description">
                    <PreviewCompatibleImage imageInfo={whyhuman.image} />
                  </div>
                </div>
                <BorderLine />
                <div className="columns client-logos">
                  <div className="column">
                    <PreviewCompatibleBgImage
                      bgTag="div"
                      bgClass="hm-full-width partners-section-wrapper"
                      imageInfo={clientlogosbg}
                      style={{
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,
                        backgroundPosition: `center top`,
                        minHeight: `830px`
                      }}>
                        <div className="section" style={{
                            width: `100%`
                          }}>
                          <div className="container">
                            <div className="columns">
                              <div className="column is-12">
                                <h2>Our partners</h2>
                                <ClientLogos gridItems={clientlogos} />
                              </div>
                            </div>
                          </div>
                        </div>
                    </PreviewCompatibleBgImage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

AboutUsPageTemplate.propTypes = {
  title: PropTypes.string,
  metadescription: PropTypes.string,
  heroimagebg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mainpitch: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string
  }),
  whatwedo: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.array,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  meetushero: PropTypes.shape({
    icons: PropTypes.array,
  }),
  humanpurposebg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  humanpurpose: PropTypes.string,
  whyhuman: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  community: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    communitybg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  teamprofiles: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    profile: PropTypes.array,
    description: PropTypes.string
  }),
  clientlogosbg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  clientlogos: PropTypes.array,
  blogs: PropTypes.array,
  helmet: PropTypes.object,
}

const AboutUsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
      <Layout>
        <AboutUsPageTemplate
          title={frontmatter.title}
          metadescription={frontmatter.metadescription}
          image={frontmatter.image}
          heroimagebg={frontmatter.heroimagebg}
          mainpitch={frontmatter.mainpitch}
          whatwedo={frontmatter.whatwedo}
          meetushero={frontmatter.meetushero}
          humanpurposebg={frontmatter.humanpurposebg}
          humanpurpose={frontmatter.humanpurpose}
          community={frontmatter.community}
          teamprofiles={frontmatter.teamprofiles}
          whyhuman={frontmatter.whyhuman}
          clientlogosbg={frontmatter.clientlogosbg}
          clientlogos={frontmatter.clientlogos}
          blogs={frontmatter.blogs}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.title}`}</title>
              <meta name="description" content={`${frontmatter.metadescription}`} />
            </Helmet>
          }
        />
      </Layout>
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "aboutus-page" } }) {
      frontmatter {
        title
        metadescription
        heroimagebg {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitch {
          heading
          description
        }
        whatwedo {
          heading
          description {
            text
          }
          image {
            childImageSharp {
              fluid(maxWidth: 520, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        meetushero {
          icons {
            image {
              childImageSharp {
                fluid(maxWidth: 520, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
        humanpurposebg {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        humanpurpose
        community {
          communitybg {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 100, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
        teamprofiles {
          heading
          subheading
          profile {
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
            title
          }
          description

        }
        whyhuman {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 676, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        blogs {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          linkurl
        }
        clientlogosbg {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        clientlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 180, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
